import file1 from '../assets/files/nbuReq/Порядок розгляду звернень споживачів фінансових послуг (друга редакція).pdf';
import file2 from '../assets/files/nbuReq/Порядок організації та проведення професійної підготовки, підвищення кваліфікації працівників (2).pdf';
import file3 from '../assets/files/nbuReq/Кодекс етики та службової поведінки працівників ТДВ Інтер-Ріск Україна (друга редакція) (1).pdf';
import file4 from '../assets/files/nbuReq/Порядок та процедура захисту персональних даних (друга редакція).pdf';
import file5 from '../assets/files/nbuReq/Порядок взаємодії із споживачами фінансових послуг.pdf'

export interface NBUReqFile {
  filename: string;
  path: string;
}
export interface NBUReqData {
  title: string;
  list: NBUReqFile[]
}



const nbuReqData: NBUReqData = {
  title: 'nav-for-debtor-nbu-req',
  list: [
    {
      filename: 'nbu-req-file-1-text',
      path: file1
    },
    {
      filename: 'nbu-req-file-2-text',
      path: file2
    },{
      filename: 'nbu-req-file-3-text',
      path: file3
    },{
      filename: 'nbu-req-file-4-text',
      path: file4
    },{
      filename: 'nbu-req-file-5-text',
      path: file5
    }
  ]
}

export default nbuReqData;
